<template>
  <b-button-group>
    <!-- {{data.item}}
    {{user}} -->
    <b-button
      v-if="$can('update', modulo)"
      variant="secondary"
      class="btn-sm btn-icon mr-1 rounded"
      title="Editar"
      @click="$emit('processGoToUpdate', data.item)"
    >
      <feather-icon
        icon="Edit2Icon"
      />
    </b-button>

    <b-button
      v-if="user.id === data.item.id"
      variant="secondary"
      class="btn-sm btn-icon rounded"
      :title='titleDelete'
      :disabled="true"
    >
      <feather-icon
        icon="TrashIcon"
      />
    </b-button>
    <b-button
      v-else
      variant="secondary"
      class="btn-sm btn-icon rounded"
      @click="!estado
        ? ''
        : $emit('processRemove', data.item.id)"
      :title="!estado
        ? titleDelete
        : 'Eliminar'"
      :disabled="!estado"
    >
      <feather-icon
        icon="TrashIcon"
      />
    </b-button>
  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
    estado: {
      type: Boolean,
      default: true,
    },
    cantidadSalidas: {
      type: Number,
      default: 0,
    },
    cantidadCompras: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    titleDelete() {
      return this.getTitleDelete()
    },
  },
  methods: {
    getTitleDelete() {
      let title = ''

      if (this.cantidadSalidas > 0 || this.cantidadCompras > 0) {
        const titleSalida = this.cantidadSalidas <= 1
          ? 'Salida creada'
          : 'Salidas creadas'
        const titleOC = this.cantidadCompras <= 1
          ? 'OC creada'
          : 'OCs creadas'

        title = `- ${titleSalida}: ${this.cantidadSalidas}
- ${titleOC}: ${this.cantidadCompras}`
      }

      if (this.user.id === this.data.item.id) {
        title = title === ''
          ? 'No puedes eliminar tu propia cuenta'
          : `No puedes eliminar tu propia cuenta
${title}`
      }

      return title
    },
  },
}
</script>
